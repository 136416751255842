:root {
  --header-height: 9vh;
  --poster-width: calc(42vw - 30px);
  --overlay-opacity: 0.75;
}
.App {
  text-align: center;
  background: linear-gradient(to right, #f93d66, #6d47d9);
  height: 100vh;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  padding: 0;
}
.movie-picker {
  display: grid;
  grid-template-rows: auto, 1fr;
  padding: 0;
}
.movies-list {
  display: grid;
  grid-template-columns: repeat(2, var(--poster-width));
  margin-bottom: 0;
  margin-top: 1vh;
  gap: 2vh 0;
  justify-content: space-evenly;
  /*  padding: 0 10px; */
  align-content: space-around;
  align-items: initial;
}
.movies-list.landscape {
  grid-template-columns: repeat(4, calc(25% - 30px));
  margin-top: 4vh;
}
.movies-list.desktop {
  grid-template-columns: repeat(auto-fit, 425px);
  gap: 40px;
  padding: 0 40px;
}
/* @media desktop screen sizes gtc */
li {
  list-style-type: none;
}
ul {
  padding-left: 0;
}
.autocomplete-list {
  position: absolute;
  overflow-y: hidden;
  max-height: calc(100vh - var(--header-height));
  background: #191717e8;
  z-index: 2;
  right: 0;
  margin-right: 15vw;
}
.movie-box {
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  overflow: hidden;
  align-content: space-between;
  box-shadow: 1px 1px 10px 10px #eb01a5, 1px 1px 10px 2px #639;
  transition: box-shadow 0.25s ease-in-out;
  /*  width: 500px; */
  /* height: 360px; */
}
.movie-box:hover {
  box-shadow: 1px 1px 2px 20px #eb01a5, 1px 1px 2px 20px #639;
}
.movie-poster-container {
  background-color: #000000;
  /* width: 500px;
  height: 740px; */
  height: 625px;
}
.movie-poster {
  object-fit: contain;
  width: 100%;
  /* grid-column: 1 / -1;
  grid-row: 1 / -1; */
}
.info-text {
  justify-items: center;
  align-items: center;
  transition: 0.2s;
  grid-template-rows: auto auto;
}
.top-bar {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(rgb(235, 1, 165), rgb(102, 51, 153) 25%);
}
.bottom-bar {
  background-color: #032c37d9;
  background: linear-gradient(rgb(102, 51, 153) 75%, rgb(235, 1, 165));
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  padding-bottom: 5px;
}
@media screen and (orientation: landscape) {
  /* all mobile landscape */
  .movie-box {
    height: calc(((45vw / 2) * 1.725) - 30px);
  }
  .bottom-bar {
    position: absolute;
    bottom: 0;
  }
  .movie-poster-container {
    height: auto;
  }
}
@media screen and (max-width: 420px) {
  /* all mobile devices in portrait */
  .movie-box {
    height: calc(45vw * 1.25);
  }
  .top-bar {
    position: absolute;
    background: linear-gradient(rgb(235, 1, 165, var(--overlay-opacity)), rgb(102, 51, 153, var(--overlay-opacity)) 25%);
  }
  .bottom-bar {
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgb(102, 51, 153, var(--overlay-opacity)) 75%, rgb(235, 1, 165, var(--overlay-opacity)));
  }
}
@media screen and (max-width: 360px) {
  /* galaxy s9 header height padding */
  .movies-list {
    margin-top: 1vh;
  }
}
@media screen and (max-height: 739px) and (max-width: 420px) and (orientation: portrait) {
  /* iPhone 6/7/8 & nexus 5x */
  .movies-list {
    margin-top: 2vh;
    gap: 1vh;
    grid-template-columns: repeat(2, 38%);
  }
  .movie-box {
    height: calc(38vw * 1.35);
  }
}
@media screen and (max-width: 739px) and (max-height: 420px) and (orientation: landscape) {
  /* iPhone 6/7/8 & nexus 5x */
  .movies-list.landscape {
    grid-template-columns: repeat(3, calc(32vw - 30px));
  }
  .movie-box {
    height: calc(33vw * 1.35);
  }
}
@media screen and (min-height: 890px) and (max-height: 900px) and (min-width: 412px) and (max-width: 415px),
  (min-height: 810px) and (max-height: 815px) and (min-width: 370px) and (max-width: 380px) {
  /* iPhone XS Max & iPhone XS*/
  .movie-box {
    height: calc(45vw * 1.35);
  }
  .movies-list {
    grid-template-columns: repeat(2, 45%);
  }
}
.movie-box:hover .top-bar {
  background: #eb01a5;
}
.movie-box:hover .bottom-bar {
  background: #eb01a5;
}
.ratings {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: end;
  padding-right: 4px;
}
.scrolling-textBox {
  width: calc(100% - 8px);
  overflow: hidden;
  padding: 0 4px;
  margin-top: -5px;
  position: relative;
  text-overflow: ellipsis;
  color: antiquewhite;
}
.title {
  white-space: normal;
  text-transform: uppercase;
  overflow: hidden;
  line-height: 1;
  max-height: 35px;
}
.year {
  padding-left: 4px;
  justify-items: baseline;
  display: grid;
}
.list-area {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
}
.header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  justify-items: flex-end;
  padding: 0 5px;
  height: var(--header-height);
  min-height: 55px;
  max-height: 75px;
}
.header-title {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
}
.search {
  padding-top: 5px;
}
.logo {
  height: 50px;
}
.logo img {
  height: 50px;
}
.logo-title {
  font-size: 1.5rem;
}
.picker {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 5px;
  justify-content: center;
}
.filters-shown {
  font-size: 0.9rem;
  background: #3b1370bd;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  margin-right: 5px;
}
.menu {
  display: none;
}
.number {
  font-size: 0.75rem;
  margin-top: -5px;
}
.range-slider {
  margin-right: 10px; /* 
  margin-top: -2.5vh;
  height: calc(100vh - var(--header-height) - 15vh); */
  width: 20px;
}
.slider {
  transform: rotate(180deg);
  height: 97%; /* 
  margin-top: 45px; */
  width: 100%;
  background: rgb(67, 6, 128);
  -webkit-appearance: slider-vertical; /* webkit & blink */
  writing-mode: bt-lr; /* IE */
}
.slider::-moz-range-thumb {
  width: 35px;
  height: 25px;
  background: #4caf50;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  width: 35px;
  height: 25px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

.slider:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}
.bubble {
  background: red;
  color: white;
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}
.bubble::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: red;
  top: -1px;
  left: 50%;
}
.poster-fullSize {
  width: 300px;
  height: 444px;
  padding: 10px 0;
}
.movie-info-pane .movie-title {
  font-size: 2rem;
  text-transform: uppercase;
}
.movie-info-pane .movie-info {
  display: grid;
  grid-template-columns: auto 1fr auto;
  background: rgba(16, 0, 57, 0.74);
  align-items: flex-end;
}
.movie-info-pane .list-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px;

  background: rgba(16, 0, 57, 0.418);
}
.movie-info-pane .file-info-hidden {
  display: none;
}
.movie-info-pane .file-info-shown {
  display: block;
}
.movie-info-pane .top {
  font-size: 1.4rem;
}
.list-links .large {
  font-size: 1.2rem;
}
.movie-info-pane .bottom {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 10px;
  background: rgba(16, 0, 57, 0.418);
}
.poster-container {
  position: relative;
}
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 75%;
}
.play-button:active {
  top: 51%;
}
button {
  display: inline-block;
  padding: 0.4em 1em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.15em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffff;
  background-color: #3369ff;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
  text-align: center;
  position: relative;
}
button:active {
  top: 0.1em;
}
button.active {
  box-shadow: 0 0 1px 2px yellow;
}
.text-input {
  width: 150px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
